function scroll_to(clicked_link, nav_height) {
  var element_class = clicked_link.attr('href').replace('#', '.');
  var parent = clicked_link.parents('li');
  $('.nav-item').removeClass("active");
  parent.addClass("active");
  console.log(parent.attr('class'));
  var classNames = parent.attr('class').split(/\s+/);
  $.each(classNames, function (index, item) {
    if (item !== 'active' && item !== 'nav-item') {
      console.log($("." + item));
      $("." + item).addClass("active");
    }
  });
  var scroll_to = 0;
  if (element_class !== '.top-content') {
    element_class += '-container';
    scroll_to = $(element_class).offset().top - nav_height;
  }
  if ($(window).scrollTop() !== scroll_to) {
    $('html, body').stop().animate({scrollTop: scroll_to}, 1000);
  }
}


$(document).ready(function () {

  /*
      Navigation
  */
  $('a.scroll-link').on('click', function (e) {
    e.preventDefault();
    scroll_to($(this), $('nav').outerHeight());
  });


  var lastId,
    topMenu = $("#nav-menu"),
    topMenuHeight = topMenu.outerHeight() + 15,
    // All list items
    menuItems = topMenu.find(".scroll-link"),
    // Anchors corresponding to menu items
    scrollItems = menuItems.map(function () {
      var item = $($(this).attr("href"));
      //console.log(item);
      if (item.length) {
        return item;
      }
    });
  var menuItemsFooter = $("#nav-footer").find(".scroll-link");
  //console.log(scrollItems);
  //console.log(menuItems);

  $(window).scroll(function () {
    // Get container scroll position
    var fromTop = $(this).scrollTop() + topMenuHeight;

    // Get id of current scroll item
    var cur = scrollItems.map(function () {
      //console.log('ici');
      if ($(this).offset().top < fromTop)
        return this;
    });
    //console.log(cur);
    //console.log(menuItems);
    // Get the id of the current element
    cur = cur[cur.length - 1];
    var id = cur && cur.length ? cur[0].id : "";

    //debugger;

    if (lastId !== id) {
      lastId = id;
      // Set/remove active class
      menuItems
        .parent().removeClass("active")
        .end().filter("[href='#" + id + "']").parent().addClass("active");
      menuItemsFooter.parent().removeClass("active")
        .end().filter("[href='#" + id + "']").parent().addClass("active");
    }
  });

  $(".back-to-top").on('click', function () {
    console.log('scroll')
    window.scrollTo(0, 0);
  })

});
